import dayjs from "dayjs";
import React, { useMemo } from "react";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import Accordion, { AccordionItemType } from "~/components/molecules/Accordion";
import { AccordionItemProps } from "~/components/molecules/Accordion/AccordionItem";
import { useAnimateProvider } from "~/contexts/AnimateProvider";
import { IMediaImageUrls } from "~/entities/media";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import { AnimationType } from "~/utilities/config/animations/animationConfig";
import { Section } from "~/utilities/enums/ProjectSections";
import scrollToView from "~/utilities/helpers/scrollToView";

export interface ProjectFAQsProps {
  project: IProject;
  showImage?: boolean;
  image?: IMediaImageUrls;
  className?: string;
  allowMultiple?: boolean;
  faqContainerClassName?: string;
  accordionClassNames?: {
    accordion?: string;
    itemAccordion?: AccordionItemProps["classNames"];
  };
  imageContainerClassName?: string;
  cssVariables?: Record<string, string>;
}

const ProjectFAQs: React.FC<ProjectFAQsProps> = ({
  project,
  showImage = true,
  image,
  className,
  faqContainerClassName,
  accordionClassNames,
  imageContainerClassName,
  allowMultiple = true,
  cssVariables,
}) => {
  const faqs: (AccordionItemType & { show: boolean })[] = useMemo(() => {
    let expectedTOP = project?.expectedTop;

    if (dayjs(expectedTOP).isValid()) {
      expectedTOP = dayjs(expectedTOP).format("MMM YYYY");
    } else {
      expectedTOP = expectedTOP?.toString();
    }

    return [
      {
        title: `What is the address for ${project.name}?`,
        content: `${project.name} is located at along ${project?.address}.`,
        show: !!project?.address,
      },
      {
        title: `How many units are available at ${project.name}?`,
        content: project?.totalUnits ? (
          `${project.name} is a ${
            project?.category?.name || "property development"
          } with a total of ${project?.totalUnits} units available.`
        ) : (
          <>
            The total number of units for ${project.name} is currently
            unavailable as the project is still in development. Please register
            your interest{" "}
            <button
              className="inline underline"
              onClick={() => scrollToView(Section.CONTACT_SALES)}
            >
              here
            </button>{" "}
            to receive updates.
          </>
        ),
        show: !!project.name,
      },
      {
        title: `What is the price for ${project.name}?`,
        content: (
          <>
            To receive the latest details, including the current price list for{" "}
            {project.name}, please register your interest{" "}
            <button
              className="inline underline"
              onClick={() => scrollToView(Section.CONTACT_SALES)}
            >
              here
            </button>
            .
          </>
        ),
        show: !!project.name,
      },
      {
        title: `What is the expected TOP date for ${project.name}?`,
        content: project?.expectedTop
          ? `The expected TOP date for ${project.name} is ${expectedTOP}.`
          : `The TOP date for ${project.name} will be announced soon.`,
        show: !!project.name,
      },
      {
        title: `Where can I find the showflat for ${project.name}?`,
        content: (
          <>
            To receive the latest details, including the showflat location for{" "}
            {project.name}, please register your interest{" "}
            <button
              className="inline underline"
              onClick={() => scrollToView(Section.CONTACT_SALES)}
            >
              here
            </button>
            .
          </>
        ),
        show: !!project.name,
      },
    ].filter((item) => item.show);
  }, [project]);

  const { registerAnimatedElement } = useAnimateProvider();
  return (
    <div className={cn("flex flex-row gap-8 lg:flex-col", className)}>
      <div className={cn("flex-1", faqContainerClassName)}>
        <h2 className="faqTitle mb-8 text-4xl font-bold text-color">FAQ</h2>
        <Accordion
          items={faqs}
          className={cn("rounded-lg", accordionClassNames?.accordion)}
          itemClassNames={{
            title: "text-lg font-semibold",
            itemContainer: "border-b last:border-b-0",
            ...accordionClassNames?.itemAccordion,
          }}
          allowMultiple={allowMultiple}
          cssVariables={cssVariables}
          enableAnimate
        />
      </div>
      {showImage && (
        <div
          className={cn(
            "relative min-h-[400px] flex-1 lg:min-h-full",
            imageContainerClassName
          )}
          ref={(e) =>
            registerAnimatedElement(e, AnimationType.SLIDE_IN_RIGHT, {
              duration: 500,
            })
          }
        >
          {image && (
            <ImageResponsive
              imageData={image}
              alt={`${project.name} FaQS`}
              className="faqImage rounded-lg object-cover"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectFAQs;
