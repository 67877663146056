import { createContext, PropsWithChildren, useContext } from "react";

import { UseAnimateReturn } from "~/utilities/config/animations/useAnimate";

export const AnimateContext = createContext<UseAnimateReturn<any>>({
  rootRef: null,
  registerAnimatedElement: () => {},
  isInView: false,
});

export const useAnimateProvider = ({
  enableProvider = true,
}: {
  enableProvider?: boolean;
} = {}) => {
  const contextValue = useContext(AnimateContext);

  if (!enableProvider) {
    return {
      rootRef: null,
      registerAnimatedElement: () => {},
      isInView: false,
    };
  }

  if (!contextValue || !contextValue?.rootRef) {
    console.warn("useAnimateProvider must be used within an AnimateProvider");
  }

  return contextValue;
};

export const AnimateProvider = ({
  children,
  ...props
}: PropsWithChildren<UseAnimateReturn<any>>) => {
  return (
    <AnimateContext.Provider
      value={{
        ...props,
      }}
    >
      {children}
    </AnimateContext.Provider>
  );
};
