import { useLoaderData } from "@remix-run/react";
import { TFunction } from "i18next";
import isEmpty from "lodash/isEmpty";
import { createContext, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import PuckConfigO1 from "./puck/PuckConfigOp1";
import PuckConfigO2 from "./puck/PuckConfigOp2";
import PuckConfigO3 from "./puck/PuckConfigOp3";
import { EMedia } from "../enums/Media";
import isValidJson from "../helpers/isValidJson";

import { IContentSection } from "~/components/organisms/ContentsNavigator/types";
import { IFloorPlan, IFloorPlanFilterOption } from "~/entities/floorPlan";
import { IMediaImageUrls } from "~/entities/media";
import { IProject } from "~/entities/project";
import {
  ISaleTransactionFilterOption,
  IUnitTransaction,
} from "~/entities/unitTransaction";
import { IVirtualTour } from "~/entities/virtualTour";
import { useIsClient } from "~/hooks/use-is-client";
import { IConfig, IPaginateResponseData } from "~/types";
import { ELDPOption } from "~/types/landingPage";
import { TProjectLoaderData } from "~/types/projectLoaderData";

export interface IPuckConfigData {
  project: IProject;
  floorPlansPagination: IPaginateResponseData<IFloorPlan>;
  unitTransactions: IPaginateResponseData<IUnitTransaction>;
  virtualTours: IVirtualTour[];
  saleTransactionFilterOptions: ISaleTransactionFilterOption[];
  floorPlanFilterOptions: IFloorPlanFilterOption[];
  validProjectMenuItems: IContentSection[];
  imageSources: IMediaImageUrls[];
  token: string;
  pageConfig: IConfig;
  i18nT: TFunction<"translation", undefined>;
  editorMode?: boolean;
}

export interface PuckContextProps {
  colorVariables: {
    root: Record<string, any>;
  };
  action: {
    setColorVariables: (data: Record<string, any>) => void;
  };
}

export const PuckContext = createContext<PuckContextProps>({
  colorVariables: {
    root: {},
  },
  action: {
    setColorVariables: () => {},
  },
});

export const usePuckContext = () => {
  return useContext(PuckContext);
};

export const usePuckConfig = ({
  editorMode = false,
}: {
  editorMode?: boolean;
}) => {
  const isClient = useIsClient();
  const { t } = useTranslation();

  const {
    config,
    project,
    projectMenuItems,
    floorPlansPagination,
    unitTransactions,
    virtualTours,
    saleTransactionFilterOptions,
    floorPlanFilterOptions,
    token,
  } = useLoaderData<TProjectLoaderData & { token: string; config: IConfig }>();

  const validProjectMenuItems = useMemo(() => {
    if (!isClient || !projectMenuItems) return projectMenuItems || [];

    return projectMenuItems.map((item) => {
      const isExist = !!document.getElementById(item.targetId);
      return {
        ...item,
        disabled: !isExist,
      };
    });
  }, [isClient, projectMenuItems]);

  const imageSources = useMemo(() => {
    if (!project || isEmpty(project)) return [];

    const medias =
      project?.medias?.filter((media) => media.type === EMedia.IMAGE) || [];

    if (project?.photo?.type === EMedia.IMAGE) {
      medias.push(project.photo);
    }

    return medias;
  }, [project]);

  const activeOption = useMemo(
    () => config?.optionMappings?.find((item) => item.active),
    [config]
  );

  const activeOptionType = useMemo(
    () => activeOption?.landingPageOption?.name,
    [activeOption]
  );

  const puckData = useMemo(() => {
    if (!activeOptionType || !activeOption) return null;

    const puckConfigData = activeOption.landingPage?.layout;
    if (!puckConfigData || !isValidJson(puckConfigData)) return null;

    const parsedData = JSON.parse(puckConfigData);
    return parsedData?.[activeOptionType] || null;
  }, [activeOption, activeOptionType]);

  const updatedConfig = useMemo(() => {
    let configGenerator;

    switch (activeOptionType) {
      case ELDPOption.O1:
        configGenerator = PuckConfigO1;
        break;
      case ELDPOption.O2:
        configGenerator = PuckConfigO2;
        break;
      case ELDPOption.O3:
        configGenerator = PuckConfigO3;
        break;
      default:
        return null;
    }

    if (!configGenerator) return null;

    return configGenerator({
      project,
      floorPlansPagination,
      unitTransactions,
      virtualTours,
      saleTransactionFilterOptions,
      floorPlanFilterOptions,
      pageConfig: config,
      validProjectMenuItems: editorMode
        ? projectMenuItems || []
        : validProjectMenuItems,
      imageSources,
      token,
      i18nT: t,
      editorMode,
    });
  }, [
    activeOptionType,
    config,
    project,
    floorPlansPagination,
    unitTransactions,
    virtualTours,
    saleTransactionFilterOptions,
    floorPlanFilterOptions,
    projectMenuItems,
    validProjectMenuItems,
    imageSources,
    token,
    t,
    editorMode,
  ]);

  return { puckConfig: updatedConfig, puckData };
};

export default usePuckConfig;
