import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import isEmpty from "lodash/isEmpty";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import SectionProjectAvailableUnits from "../SectionProjectAvailableUnits";
import SectionProjectFloorPlans from "../SectionProjectFloorPlans";
import SectionProjectSaleTransaction from "../SectionProjectSaleTransaction";

import Button from "~/components/atoms/Button";
import Typo from "~/components/atoms/Typo";
import SectionProjectUnitMix from "~/components/sections/SectionProjectUnitMix";
import { IFloorPlan, IFloorPlanFilterOption } from "~/entities/floorPlan";
import { IProject } from "~/entities/project";
import {
  ISaleTransactionFilterOption,
  IUnitTransaction,
} from "~/entities/unitTransaction";
import { IPaginateResponseData } from "~/types";
import { cn } from "~/utilities/cn";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { Section } from "~/utilities/enums/ProjectSections";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

interface SectionUnitsLDPProps {
  className?: string;
  project: IProject;
  floorPlansPagination: IPaginateResponseData<IFloorPlan>;
  floorPlanFilterOptions: IFloorPlanFilterOption[];
  unitTransactions: IPaginateResponseData<IUnitTransaction>;
  saleTransactionFilterOptions: ISaleTransactionFilterOption[];
}

const SectionUnitsLDP: React.FC<SectionUnitsLDPProps> = ({
  project,
  floorPlansPagination,
  floorPlanFilterOptions,
  unitTransactions,
  saleTransactionFilterOptions,
  className = "",
}) => {
  const { t } = useTranslation();

  const tabsList: any[] = useMemo(() => {
    return [
      {
        TabPanel: (
          <SectionProjectUnitMix
            id=""
            title={projectSectionConfig[Section.UNIT_MIX].title}
            description={projectSectionConfig[Section.UNIT_MIX].description}
            project={project}
            className="lg:gap-6 lg:py-1.5"
          />
        ),
        hidden: isEmpty(project?.unitTypes),
        title: t(projectSectionConfig[Section.UNIT_MIX].title),
        targetId: Section.UNIT_MIX,
      },
      {
        TabPanel: (
          <SectionProjectAvailableUnits
            id=""
            title={projectSectionConfig[Section.AVAILABLE_UNITS].title}
            description={
              projectSectionConfig[Section.AVAILABLE_UNITS].description
            }
            project={project}
          />
        ),
        hidden: isEmpty(
          project.unitTypes.filter((item) => !!item.availableUnits)
        ),
        title: t(projectSectionConfig[Section.AVAILABLE_UNITS].title),
        targetId: Section.AVAILABLE_UNITS,
      },
      {
        TabPanel: (
          <SectionProjectFloorPlans
            id=""
            title={projectSectionConfig[Section.FLOOR_PLANS].title}
            description={projectSectionConfig[Section.FLOOR_PLANS].description}
            floorPlansPagination={floorPlansPagination}
            project={project}
            unitTypeFilterOptions={floorPlanFilterOptions}
          />
        ),
        hidden: isEmpty(floorPlansPagination.items),
        title: t(projectSectionConfig[Section.FLOOR_PLANS].title),
        targetId: Section.FLOOR_PLANS,
      },
      {
        TabPanel: (
          <SectionProjectSaleTransaction
            id=""
            title={projectSectionConfig[Section.SALES_TRANSACTION].title}
            description={
              projectSectionConfig[Section.SALES_TRANSACTION].description
            }
            unitTransactions={unitTransactions}
            saleTransactionFilterOptions={saleTransactionFilterOptions}
          />
        ),
        hidden: isEmpty(unitTransactions.items),
        title: t(projectSectionConfig[Section.SALES_TRANSACTION].title),
        targetId: Section.SALES_TRANSACTION,
      },
    ].filter((item) => !item.hidden);
  }, [
    project,
    floorPlanFilterOptions,
    floorPlansPagination,
    unitTransactions,
    saleTransactionFilterOptions,
    t,
  ]);

  const isHideSection = tabsList.every((item) => item.hidden);

  if (isHideSection) return <></>;
  const defaultActive = tabsList.findIndex((item) => !item.hidden);

  return (
    <section className="w-full overflow-hidden bg-[var(--bg-section)] py-20 lg:py-7">
      <TabGroup
        defaultIndex={defaultActive}
        vertical
        className={cn(
          "grid grid-cols-4 lg:grid-cols-1 lg:gap-12",
          className,
          "lg:px-0"
        )}
      >
        <TabList className="col-span-1 flex flex-col items-start justify-start gap-6 lg:flex-row lg:gap-3 lg:overflow-x-auto lg:px-4">
          {tabsList.map((item, idx) => (
            <Tab
              key={item?.targetId || idx}
              hidden={item.hidden}
              className="outline-none ring-0"
            >
              {({ selected }) => (
                <Button
                  variant={selected ? "default" : "outline"}
                  animation={selected ? "effectMove" : "none"}
                  className={cn(
                    "rounded-full",
                    selected
                      ? "!bg-[var(--bg-active)] !text-[var(--color-active)]"
                      : "!border-[var(--color-text)] !bg-transparent !text-[var(--color-text)]"
                  )}
                >
                  <Typo
                    className={cn(
                      "whitespace-nowrap font-normal capitalize",
                      selected
                        ? "!text-[var(--color-active)]"
                        : "!text-[var(--color-text)]"
                    )}
                    tag={ETypoTag.SPAN}
                    variant={ETypoVariant.SUB_TITLE_20}
                  >
                    {item.title}
                  </Typo>
                </Button>
              )}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="col-span-3  bg-[var(--bg-section)] px-6 lg:col-span-1  lg:-mx-6 lg:px-0 [&_.unit-analysis-title>*]:!text-[var(--color-text)] [&_tbody>tr]:bg-[var(--bg-section)] [&_tbody>tr]:text-[var(--color-table-text)] [&_thead>tr]:bg-[var(--bg-table-head)] [&_thead_th]:text-[var(--color-table-head-text)]">
          {tabsList.map((tab, index) => (
            <TabPanel
              id={tab?.targetId}
              key={tab?.targetId}
              hidden={tab.hidden}
            >
              {tab.TabPanel}
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </section>
  );
};

export default SectionUnitsLDP;
