import * as React from "react";

import Typo from "~/components/atoms/Typo";
import ContactSaleForm from "~/components/molecules/ContactSales/ContactSalesForm";
import { cn } from "~/utilities/cn";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface LDPContactSaleFormProps {
  className?: string;
  style?: React.CSSProperties;
}

const LDPContactSaleForm = React.forwardRef<
  HTMLDivElement,
  LDPContactSaleFormProps
>(({ className, style }, ref) => {
  return (
    <div
      className={cn(
        "rounded-bl-[180px] bg-[var(--bg-contact-form)] p-12 lg:rounded-bl-[100px] lg:p-6",
        className
      )}
      style={{
        // @ts-expect-error - CSS variable
        "--bg-contact-form": "#ffffff",
        ...style,
      }}
      ref={ref}
    >
      <div className="flex flex-col gap-y-12">
        <Typo
          variant={ETypoVariant.HEADER_56}
          tag={ETypoTag.H3}
          className="font-bold  lg:text-header-32"
        >
          Contact Sales
        </Typo>

        <ContactSaleForm />
      </div>
    </div>
  );
});

LDPContactSaleForm.displayName = "LDPContactSaleForm";

export default LDPContactSaleForm;
