import { useEffect, useRef } from "react";
import ReactQuill, { Quill, type ReactQuillProps } from "react-quill";

import "react-quill/dist/quill.snow.css";
import "./styles.css";

import { cn } from "~/utilities/cn";
import { removeNbspParagraphs } from "~/utilities/helpers/removeNbspParagraphs";

const generateFontSizeArray = ({
  max = 120,
  defaultFontSize = 16,
  step = 2,
}: {
  max: number;
  step?: number;
  defaultFontSize?: number;
}) => {
  const arr = [`${defaultFontSize}px`];

  for (let i = 2; i <= max; i = i + step) {
    if (i === defaultFontSize) {
      continue;
    }

    arr.push(`${i}px`);
  }

  return arr;
};

const fontSizeArr = generateFontSizeArray({ max: 120 });

const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

export interface QuillEditorProps extends ReactQuillProps {
  error?: string;
  label?: React.ReactNode;
  className?: string;
  labelClassName?: string;
  errorClassName?: string;
  toolbarPosition?: "top" | "bottom";
  toolbar?: any[];
  topMessageBackground?: string;
}

const quillModules = {
  toolbar: [
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: Size.whitelist }],

    ["bold", "italic", "underline", "strike"], // toggled buttons
    // ["blockquote", "code-block"],
    ["link"],

    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }], // superscript/subscript
    // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ],
};
export default function QuillEditor({
  label,
  error,
  className,
  labelClassName,
  errorClassName,
  toolbarPosition = "top",
  toolbar,
  topMessageBackground = "transparent",
  value,
  onChange,
  defaultValue,
  ...props
}: QuillEditorProps) {
  const quillRef = useRef<ReactQuill | null>(null);

  if (toolbar && toolbar.length > 0) {
    quillModules.toolbar = toolbar;
  }

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = quillRef.current.getEditorConfig();

      editor.root.style.backgroundColor = topMessageBackground;
      editor.root.style.fontSize = "16px";
    }
  }, [topMessageBackground, quillRef.current]);

  return (
    <div className={cn("prose", className)}>
      {label && (
        <label
          className={cn("mb-1.5 block text-sm font-semibold", labelClassName)}
        >
          {label}
        </label>
      )}
      <ReactQuill
        modules={quillModules}
        ref={quillRef}
        theme="snow"
        className={cn(
          "react-quill [&_.ql-container.ql-snow]:p-2",
          toolbarPosition === "bottom" && "react-quill-toolbar-bottom relative",
          "[&_.ql-snow_.ql-picker.ql-size_.ql-picker-label:before]:content-[attr(data-value)]",
          "[&_.ql-snow_.ql-picker.ql-size_.ql-picker-item:before]:content-[attr(data-value)]",
          className
        )}
        defaultValue={defaultValue}
        value={value}
        onChange={(value, delta, source, editor) => {
          const isInsertDefault =
            delta?.ops?.length === 1 &&
            delta?.ops?.[0]?.insert &&
            delta?.ops?.[0]?.insert ===
              removeNbspParagraphs((defaultValue as string) || "");

          if (isInsertDefault) return;

          onChange?.(value, delta, source, editor);
        }}
        {...props}
      />
    </div>
  );
}
