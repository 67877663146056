import { useState } from "react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import LDPSection, { LDPSectionProps } from "../LDPSection";
import LDPTitle from "../LDPTitle";

import CleanedContent from "~/components/atoms/CleanedContent";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import ModalMediaGallery from "~/components/organisms/ModalMediaGallery";
import { IMediaImageUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";
import { AnimationType } from "~/utilities/config/animations/animationConfig";
import { useAnimate } from "~/utilities/config/animations/useAnimate";

export interface LDPSitePlanProps extends LDPSectionProps {
  title?: string;
  description?: string;
  sitePlanImages: IMediaImageUrls[];
  sitePlanHtml: string;
  hasBackgroundImg?: boolean;
  imgClassName?: string;
  pictureClassname?: string;
  classNameContainer?: string;
}

export default function LDPSitePlan({
  id,
  title = "Site Plans",
  description,
  sitePlanHtml,
  styleRoot,
  sitePlanImages,
  hasBackgroundImg,
  imgClassName,
  pictureClassname,
  classNameContainer,
}: LDPSitePlanProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => setIsOpen(false);

  const { registerAnimatedElement, rootRef } = useAnimate<HTMLDivElement>();

  return (
    <LDPSection
      styleRoot={{
        ...styleRoot,
      }}
      classNameSection="lg:py-12 py-[98px] [&_*]:!text-[var(--color-text)] overflow-x-hidden"
      className="h-full"
      id={id}
      ref={rootRef}
    >
      <div
        className={cn(
          "grid h-full grid-cols-[400px_1fr] gap-6 lg:grid-cols-1 lg:gap-8",
          classNameContainer
        )}
        ref={(e) => registerAnimatedElement(e, AnimationType.SCALE_IN_CENTER)}
      >
        <div className="sitePlan-content flex h-full max-h-[475px] flex-col gap-12  pl-[82px] text-color  lg:max-h-full lg:gap-8 lg:pl-0">
          <LDPTitle title={title} description={description} />
          <CleanedContent
            className="scroll overflow-auto"
            htmlContent={sitePlanHtml}
          />
        </div>

        {sitePlanImages?.length && (
          <Swiper
            slidesPerView={1}
            modules={[Autoplay, EffectFade]}
            autoplay={{
              delay: 5000,
            }}
            loop
            effect="fade"
            spaceBetween={16}
            className="w-full overflow-visible"
            breakpoints={{
              768: {
                spaceBetween: 24,
                slidesPerView: "auto",
              },
            }}
            // onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex + 1)}
          >
            {sitePlanImages?.map((sitePlan, idx) => (
              <SwiperSlide
                onClick={() => {
                  handleOpenModal();
                  setCurrentSlide(idx);
                }}
                key={sitePlan?.id || idx}
              >
                <div className="sitePlan-img lg:max-h-auto relative h-auto w-full pl-[82px]  lg:pl-0">
                  <ImageResponsive
                    // after for background of image
                    pictureClassName={cn(
                      "relative  block  z-10 h-full w-full object-cover",
                      hasBackgroundImg &&
                        "after:absolute after:inset-0 after:-bottom-0 after:right-0 after:z-[1] after:h-full after:bg-[--bg-inner] after:rounded-br-[160px] lg:after:-mx-4 after:translate-y-[96px] lg:after:translate-y-[64px] after:translate-x-[96px] lg:after:translate-x-[0px]  after:lg:rounded-br-[100px]",
                      pictureClassname
                    )}
                    className={cn(
                      "relative z-10 h-[475px] w-full rounded-bl-[160px] rounded-tr-[160px] lg:aspect-[343/240] lg:h-[240px] lg:rounded-bl-[100px] lg:rounded-tr-[100px] lg:pr-10",
                      imgClassName
                    )}
                    imageData={sitePlan}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {isOpen && (
          <ModalMediaGallery
            medias={sitePlanImages}
            closeModal={handleCloseModal}
            isOpen={isOpen}
            indexSlide={currentSlide}
          />
        )}
      </div>
    </LDPSection>
  );
}
