import LDPSection, { LDPSectionProps } from "../LDPSection";

import ProjectFAQs, {
  ProjectFAQsProps,
} from "~/components/organisms/ProjectFAQs";
import { AnimateProvider } from "~/contexts/AnimateProvider";
import { cn } from "~/utilities/cn";
import { useAnimate } from "~/utilities/config/animations/useAnimate";

export interface LDPFaQsrops
  extends Omit<ProjectFAQsProps, "className">,
    Omit<LDPSectionProps, "children"> {
  faqClassName?: string;
}

export default function LDPFaQs({
  styleRoot,
  id,
  className,
  classNameSection,
  faqClassName,
  ...props
}: LDPFaQsrops) {
  const animate = useAnimate<HTMLDivElement>();

  return (
    <AnimateProvider {...animate}>
      <LDPSection
        styleRoot={styleRoot}
        id={id}
        className={cn("py-12", className)}
        classNameSection={classNameSection}
        ref={animate?.rootRef}
      >
        <ProjectFAQs
          className={faqClassName}
          cssVariables={styleRoot}
          {...props}
        />
      </LDPSection>
    </AnimateProvider>
  );
}
