import React from "react";

import AccordionItem, { AccordionItemProps } from "./AccordionItem";

import { useAnimateProvider } from "~/contexts/AnimateProvider";
import { cn } from "~/utilities/cn";
import { AnimationType } from "~/utilities/config/animations/animationConfig";

export interface AccordionItemType {
  title: React.ReactNode;
  content: React.ReactNode;
  defaultOpen?: boolean;
}

export interface AccordionProps {
  items: AccordionItemType[];
  allowMultiple?: boolean;
  itemClassNames?: AccordionItemProps["classNames"];
  className?: string;
  cssVariables?: Record<string, string>;
  enableAnimate?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  items,
  allowMultiple = false,
  itemClassNames,
  className,
  cssVariables,
  enableAnimate = false,
}) => {
  const { registerAnimatedElement, isInView, rootRef } = useAnimateProvider({
    enableProvider: enableAnimate,
  });

  return (
    <div
      style={{
        // @ts-expect-error - CSS property
        "--spacing-content": `24px`,
        ...cssVariables,
      }}
      className={cn("join join-vertical w-full", className)}
    >
      {items.map((item, index) => (
        <AccordionItem
          classNames={itemClassNames}
          key={index}
          ref={(e) => {
            registerAnimatedElement?.(e, AnimationType.FADE_IN, {
              duration: 200,
              delay: index * 200,
            });
          }}
          {...item}
          index={index}
          allowMultiple={allowMultiple}
        />
      ))}
    </div>
  );
};

export default Accordion;
