import { SCROLL_OFFSET } from "../constants/projectSections";

export default function scrollToView(targetId: string, offset: number = SCROLL_OFFSET, puckPreview?: boolean) {
    // TODO: Optimize this function

    let currentDoc = document


    // Wait for the iframe to load before accessing the content

    if (puckPreview) {

        document.addEventListener("DOMContentLoaded", function () {
            // Get the iframe in the document
            const iframe = document.getElementById("preview-frame") as HTMLIFrameElement;

            if (!iframe) {
                console.error("Preview iframe not found");
                return;
            }

            try {
                const frameDoc = iframe.contentDocument || iframe.contentWindow?.document;


                if (frameDoc) {
                    currentDoc = frameDoc;
                } else {
                    console.log("Could not access iframe document - possible cross-origin issue");
                }
            } catch (error) {
                console.log("Error accessing iframe content:", error);
            }
        });

    }

    console.log(currentDoc)
    if (!currentDoc) return

    // Make sure all element wrapper tag name is section
    let sectionEl = currentDoc.getElementById(targetId);

    if (sectionEl && sectionEl?.tagName.toLocaleLowerCase() !== "section") {
        // For group  section ids, example: landing page table units
        sectionEl = sectionEl?.closest("section");
    }

    console.log(sectionEl)

    if (sectionEl) {
        const y =
            sectionEl.getBoundingClientRect().top + window.scrollY + offset;

        // Update the URL hash without scrolling again
        window.history.pushState(null, "", `#${targetId}`);
        // if (y === window.scrollY + offset) return; // return if window not change

        window.scrollTo({ top: y, behavior: "smooth" });
    }
}
