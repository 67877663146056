import { ReactNode } from "react";

import AdditionalCTABgImage from "~/assets/images/additional-background.jpg";
import Button from "~/components/atoms/Button";
import CleanedContent from "~/components/atoms/CleanedContent";
import { Icon } from "~/components/atoms/Icons";
import Image from "~/components/atoms/Image";
import ImageResponsive, {
  ImageResponsiveProps,
} from "~/components/atoms/ImageResponsive";
import { IMediaImageUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";
import { AnimationType } from "~/utilities/config/animations/animationConfig";
import { useAnimate } from "~/utilities/config/animations/useAnimate";
import { EIcon } from "~/utilities/enums/Icons";

export interface LDPAdditionalCTAProps {
  title?: ReactNode;
  description?: ReactNode;
  cta?: {
    label?: string;
    onClick?: () => void;
  };
  image?: IMediaImageUrls;
  imagesProps?: Omit<ImageResponsiveProps, "imageData" | "alt">;
  className?: string;
  cssVariables?: Record<string, string>;
}

export function LDPAdditionalTitle({ district }: { district?: string }) {
  return (
    <div className="space-y-8 lg:space-y-6">
      <h2 className="text-xl font-bold uppercase text-white lg:text-lg">
        Limited Units Available!
      </h2>
      <p className="text-2xl/[1.25] font-bold uppercase text-white lg:text-lg">
        Prime Location with Excellent Connectivity in {district}
      </p>
      <p className="text-2xl/[1.25] font-bold uppercase text-white lg:text-lg">
        Register Your Interest Today!
      </p>
    </div>
  );
}
export default function LDPAdditionalCTA({
  title = <LDPAdditionalTitle />,
  description,
  cta,
  image,
  imagesProps,
  className: wrapperClassName,
  cssVariables,
}: LDPAdditionalCTAProps) {
  const { pictureClassName, ...restImagesProps } = imagesProps || {};

  const animate = useAnimate<HTMLDivElement>();

  const { registerAnimatedElement, isInView, rootRef } = animate || [];

  return (
    <div
      style={{
        // @ts-expect-error - Variables
        "--color-text": "#ffffff",
        "--bg-image-layer": "#000000",
        "--bg-image-layer-opacity": "25",
        "--bg-button": "var(--color-color)",
        "--text-button": "#ffffff",
        ...cssVariables,
      }}
      className={cn(
        "relative flex h-full min-h-[600px]  w-full  items-center justify-start overflow-hidden py-20 after:absolute after:inset-0 after:z-[1] after:h-full after:w-full after:bg-[var(--bg-image-layer)] after:opacity-[calc(var(--bg-image-layer-opacity)/100)] after:content-['']",
        wrapperClassName
      )}
      ref={rootRef}
    >
      {image ? (
        <ImageResponsive
          {...restImagesProps}
          imageData={image}
          alt={
            typeof title === "string"
              ? `additional-cta-${title}`
              : "additional-cta-title"
          }
          pictureClassName={cn(
            "absolute left-0 top-0 h-full w-full object-cover",
            pictureClassName
          )}
        />
      ) : (
        <Image
          isLocalImage
          url={AdditionalCTABgImage}
          className="absolute left-0 top-0 h-full w-full object-cover"
        />
      )}

      <div className="ldp-container relative z-10">
        <div className="content  relative z-20 flex h-full w-1/2 flex-grow flex-col space-y-8 overflow-hidden text-[var(--color-text)] lg:w-full lg:space-y-6">
          {title && typeof title === "string" ? (
            <CleanedContent
              ref={(e) =>
                registerAnimatedElement(e, AnimationType.SCALE_IN_TOP, {
                  duration: 500,
                })
              }
              className="quillRenderTitle animate-scaleInTop"
              htmlContent={title}
            />
          ) : (
            <div
              ref={(e) =>
                registerAnimatedElement(e, AnimationType.SCALE_IN_TOP, {
                  duration: 500,
                })
              }
            >
              {title}
            </div>
          )}
          {description && typeof description === "string" ? (
            <CleanedContent
              ref={(e) =>
                registerAnimatedElement(e, AnimationType.SLIDE_IN_LEFT, {
                  duration: 500,
                  delay: 500,
                })
              }
              className="quillRenderDescription"
              htmlContent={description}
            />
          ) : (
            <div
              ref={(e) =>
                registerAnimatedElement(e, AnimationType.SLIDE_IN_LEFT, {
                  duration: 500,
                  delay: 500,
                })
              }
            >
              {description}
            </div>
          )}
          <div
            ref={(e) =>
              registerAnimatedElement(e, AnimationType.SCALE_IN_BOTTOM, {
                duration: 500,
                delay: 1000,
              })
            }
            className="flex flex-col gap-4"
          >
            <Button
              className="w-full max-w-fit rounded-md bg-[var(--bg-button)] p-6 uppercase text-[var(--text-button)] lg:p-4"
              animation="effectMove"
              onClick={cta?.onClick}
            >
              <Icon
                TypeIcon={EIcon.FingerPrintIcon}
                className="h-4 w-4 [&_svg]:!stroke-[var(--text-button)]"
              />

              <span>{cta?.label || "Get Access Now"}</span>
            </Button>
            <small className="text-[10px] text-[var(--color-text)] ">
              *The information is subject to change and photos/images used are
              for illustration purposes only.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
