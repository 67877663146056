import LDPSection, { LDPSectionProps } from "./LDPSection";
import Image from "../atoms/Image";
import Typo from "../atoms/Typo";

import coLogo from "~/assets/images/99co.png";
import btLogo from "~/assets/images/bt-digital-logo.png";
import propertyGuru from "~/assets/images/property-guru.png";
import stLogo from "~/assets/images/straits-times-logo.png";
import edgeLogo from "~/assets/images/the-edge-sg.png";
import { cn } from "~/utilities/cn";
import { AnimationType } from "~/utilities/config/animations/animationConfig";
import { useAnimate } from "~/utilities/config/animations/useAnimate";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface LDPPartnersProps extends LDPSectionProps {
  hasBorder?: boolean;
}

export default function LDPPartners({
  styleRoot,
  hasBorder = true,
  ...rest
}: LDPPartnersProps) {
  const { registerAnimatedElement, rootRef, isInView } =
    useAnimate<HTMLDivElement>() || {};

  return (
    <LDPSection
      styleRoot={{
        ...VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPPartners],
        ...styleRoot,
      }}
      ref={rootRef}
      className="py-24 sm:py-12 [&_*]:!text-[var(--color-text)]"
      {...rest}
    >
      <div className="flex flex-col items-center justify-center gap-6">
        <Typo tag={ETypoTag.H5} variant={ETypoVariant.HEADER_24}>
          As seen on
        </Typo>

        <div
          className={cn(
            "partner-wrapper grid grid-cols-2 items-center gap-16  px-12 py-9 sm:grid-cols-1 sm:gap-6 sm:rounded-xl sm:px-5 sm:py-6",
            hasBorder && "rounded-full border border-[var(--color-text)]"
          )}
        >
          <div className="grid grid-cols-2 gap-16 sm:gap-6">
            <div
              ref={(e) =>
                registerAnimatedElement?.(e, AnimationType.SCALE_IN_CENTER, {
                  duration: 200,
                })
              }
            >
              <Image className="object-contain" isLocalImage url={btLogo} />
            </div>
            <div
              ref={(e) =>
                registerAnimatedElement?.(e, AnimationType.SCALE_IN_CENTER, {
                  duration: 200,
                  delay: 200,
                })
              }
            >
              <Image className="object-contain" isLocalImage url={stLogo} />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-16 sm:gap-6">
            <div
              ref={(e) =>
                registerAnimatedElement?.(e, AnimationType.SCALE_IN_CENTER, {
                  duration: 200,
                  delay: 400,
                })
              }
            >
              <Image className="object-contain" isLocalImage url={coLogo} />
            </div>
            <div
              ref={(e) =>
                registerAnimatedElement?.(e, AnimationType.SCALE_IN_CENTER, {
                  duration: 200,
                  delay: 600,
                })
              }
            >
              <Image
                className="object-contain"
                isLocalImage
                url={propertyGuru}
              />
            </div>
            <div
              ref={(e) =>
                registerAnimatedElement?.(e, AnimationType.SCALE_IN_CENTER, {
                  duration: 200,
                  delay: 800,
                })
              }
            >
              <Image className="object-contain" isLocalImage url={edgeLogo} />
            </div>
          </div>
        </div>
      </div>
    </LDPSection>
  );
}
