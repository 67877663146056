import { forwardRef, ForwardRefRenderFunction, ReactNode } from "react";

import { AccordionItemType } from ".";

import { cn } from "~/utilities/cn";

export interface AccordionItemProps extends AccordionItemType {
  allowMultiple?: boolean;
  index: number;
  classNames?: {
    title?: ReactNode;
    content?: ReactNode;
    itemContainer?: string;
  };
}

const AccordionItem: ForwardRefRenderFunction<
  HTMLDivElement,
  AccordionItemProps
> = (
  { title, content, defaultOpen, allowMultiple, index, classNames },
  ref
) => {
  return (
    <div
      className={cn(
        "collapse join-item collapse-plus rounded-none border-t border-[#172C3740] border-opacity-15 first:border-t-0",
        classNames?.itemContainer
      )}
      ref={ref}
    >
      <input
        type={allowMultiple ? "checkbox" : "radio"}
        name={allowMultiple ? `accordion-${index}` : "accordion-group"}
        defaultChecked={defaultOpen}
        className="peer"
      />
      <div
        className={cn(
          "collapse-title py-[var(--spacing-content)] text-xl font-medium text-color after:!top-[var(--spacing-content)] after:!size-auto after:text-[32px] after:!font-normal",
          classNames?.title
        )}
      >
        {title}
      </div>
      <div
        className={cn(
          "collapse-content peer-checked:!pb-[var(--spacing-content)]",
          classNames?.content
        )}
      >
        <p className="text-base text-color">{content}</p>
      </div>
    </div>
  );
};

export default forwardRef(AccordionItem);
